var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"container-lg px-4 content py-5"},[_c('div',{staticClass:"col-12"},[_c('a',{staticClass:"btn btn-outline-primary mb-3",attrs:{"role":"button"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" 返回列表 ")])]),_c('div',{staticClass:"col-12 title fs-1 fw-bold"},[_vm._v("算法服务平台开发工程师")]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jumbotron-wrapper image"},[_c('div',{staticClass:"jumbotron container-lg px-4"},[_c('div',{staticClass:"animation cover-text mt-5 pt-5 ps-0"},[_c('div',[_vm._v("和我们，做有挑战的事！")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"badge border text-primary border-primary"},[_vm._v("开发")]),_c('div',{staticClass:"ms-2 badge border text-primary border-primary"},[_vm._v(" 社会招聘 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("岗位描述")]),_c('div',{staticClass:"mt-2"},[_c('ul',{staticClass:"list-unstyled mb-4"},[_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 设计并开发基于 Rust 的高性能，低时延算法交易系统； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 设计并开发数据处理平台，监控运维平台； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 设计并开发面向客户的高可用交易工具等； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 设计并开发策略相关的回测平台。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"requirement mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("岗位要求")]),_c('div',{staticClass:"mt-2"},[_c('ul',{staticClass:"list-unstyled mb-4"},[_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 本科及以上学历。编程基础扎实，具有良好的计算机理论基础； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 熟练掌握Linux操作，性能分析，具备Rust/C/C++/Java丰富开发经验，熟悉常用的设计模式，有分布式系统开发经验加分； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 有研发高性能，低时延系统经验加分； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 对技术充满热情，思考深入。自我驱动，能快速学习新鲜事物。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"location mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("工作城市")]),_c('div',{staticClass:"mt-2"},[_c('span',[_vm._v(" 上海 ")]),_c('span',[_vm._v(" 北京 ")]),_c('span',[_vm._v(" 深圳 ")]),_c('span',[_vm._v(" 成都 ")]),_c('span',[_vm._v(" 徐州 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("联系方式")]),_c('div',{staticClass:"mb-2"},[_vm._v("简历请发送至邮箱：recruit@ft.tech")]),_c('img',{attrs:{"src":require("../../assets/img/招聘求职.jpg"),"align":"center"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3 d-flex justify-content-center"},[_c('a',{staticClass:"btn btn-primary px-5",attrs:{"href":"mailto:recruit@ft.tech"}},[_vm._v(" 简历投递 ")])])
}]

export { render, staticRenderFns }